.Layout {
  background-image: url(https://image.freepik.com/vector-gratis/fondo-blanco-gris-brillante-lineas-onduladas_1017-25101.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.leaflet-container {
  max-width: 100%;
  height: 400px;
  border: 1px solid #6200EE;
  border-radius: 25px;
  z-index: 0;
}